"use client"
import { useGetQuery } from "@/hooks";
import styles from "./index.module.scss";
import Translate from "@/i18n";
import { UseQueryResult, useQueryClient } from "@/utils/@tanstack/react-query";
import InitInterface from "@/types/initInterface";
import Link from "next/link";
import { Activity } from "@/utils/constants";
import { generateUrl, removeUserData } from "@/utils/common";



export default function ActivityList() {
    const { data } = useGetQuery(['init']) as UseQueryResult<InitInterface>;
    const queryClient = useQueryClient();
    const logoutUser = () => {
        queryClient.setQueryData(['init'], false);
        removeUserData();
    };

    return (
        <main className={styles.content}>
            <h1><Translate text="select_leaderboard" /></h1>
            <div className={styles.list}>
                <div><Link href={`/overall`}>{Activity['overall']}</Link></div>
                {data?.data?.performances.map(item =>
                    <div key={item.value}><Link href={`/${item.value}`}>{Activity[item.value]}</Link></div>
                )}
            </div>
            <div className={styles.config}><Link href={`/performance`}><Translate text="open_performance_tool" /></Link></div>
            <div className={styles.newEvent} onClick={() => logoutUser()}><Translate text="logout_from_event" /></div>
        </main>
    );
}
