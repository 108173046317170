'use client'
import React from 'react';
import ActivityList from "@/components/activityList";
import styles from "./page.module.css";


export default function Home() {

  return (
    <div className={styles.main}>
      <ActivityList />
    </div>
  );
}
